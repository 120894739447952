<template>
  <div>
    <div class="isNotweixin" v-if="isnotweixin">
      <div class="isNotweixin-box">
        <img src="@/assets/images/gantan.jpg" alt="" />
        <span>仅限微信浏览器访问，请在微信客户端打开链接</span>
      </div>
    </div>
    <div style="text-align: center">{{ msg }}</div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import router from '../router'

export default {
  components: {},
  data() {
    return {
      isnotweixin: false,
      csStr: "",
      msg: "正在进行微信登录请稍等",
    };
  },
  computed: {
    ...mapGetters(["getToken", "getLastPath"])
  },
  mounted() {
    let _this = this
    let param = _this.$route.query
    if (_this.isWeixinBrowser()) {
      if (param && param.code && param.state) {
        // alert(3)
        // alert(decodeURIComponent(param.state))
        _this.$store.commit("setLastPath", decodeURIComponent(param.state));
        _this.$store.dispatch("getWxLogin", _this.$route.query).then((res) => {
          if (res.code === 0) {
            //登录成功
            _this.$store.commit('setLoginToken', res.data)
            _this.$router.push(_this.getLastPath);
          } else {
            _this.$store.commit('setWxOpenId', res.data)
            console.log('------', res.data)
            _this.$router.push('Login')
          }
        })
      } else {
        // alert(2)
        // alert(_this.getLastPath)
        // console.log('-222--wxlogin-----', _this.getLastPath)
        // console.log('--222-localStorage-----', localStorage)
        window.location.href = "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxff43c2b546c5196a&redirect_uri=http://www.china6588.com/wxLogin&response_type=code&scope=snsapi_base&state="+encodeURIComponent(_this.getLastPath)+"#wechat_redirect";
        // window.location.href = "http://192.168.0.106:8080/wxLogin?code=1212&state="+encodeURIComponent(_this.getLastPath);
      }
    } else {
      _this.$router.push({ name: "Login" });
      // window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxff43c2b546c5196a&redirect_uri=http://www.china6588.com/wxLogin&response_type=code&scope=snsapi_base&state=STATE#wechat_redirect`;
    }
    // 如果不是微信浏览器
    // if (!this.isWeixinBrowser()) {
    //   this.isnotweixin = true;
    // } else {
    //   this.getKey();
      // var dz = window.location.href;
      // console.log(dz.includes("code"));
      // if (dz.includes("code")) {
      //   this.getKey();
      // } else {
      //   let url = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxff43c2b546c5196a&redirect_uri=http://www.china6588.com/wxLogin&response_type=code&scope=snsapi_base&state=STATE#wechat_redirect`;
      //   window.location.href = url;
      //   this.getKey();
      // }
    // }
  },
  methods: {
    // getKey() {
    //   //var url = `http://www.china6588.com/wxLogin?code=0516BxFa1ykiQE00ZgJa1l0ycC46BxFr&state=STATE`;
    //   var url = window.location.href; //获取当前url
    //   var dz_url = url.split("#")[0]; //获取#/之前的字符串
    //   var cs = dz_url.split("?")[1]; //获取?之后的参数字符串
    //   var cs_arr = cs.split("&"); //参数字符串分割为数组
    //   var cs = {};
    //   for (var i = 0; i < cs_arr.length; i++) {
    //     //遍历数组，拿到json对象
    //     cs[cs_arr[i].split("=")[0]] = cs_arr[i].split("=")[1];
    //   }
    //   if (cs.code) {
    //     this.$store.dispatch("getWxLogin", cs).then((res) => {
    //       if (res.access_token) {
    //         localStorage.setItem("LbjtAccessToken", res.access_token);
    //         localStorage.setItem("LbjtTokenType", res.token_type);
    //         //this.$router.push("/login");.
    //         setTimeout(() => {
    //           let wxSetLastPath = localStorage.getItem("setLastPath");
    //           this.$router.push(wxSetLastPath);
    //         }, 1000);
    //
    //         return;
    //       }
    //       this.msg = "微信未授权或请求出错了";
    //       localStorage.setItem("LbjtAccessToken", res.data.token);
    //       sessionStorage.setItem("openid", res.data.id);
    //       this.$router.push("/login");
    //     });
    //   }
    // },
    isWeixinBrowser() {
      let ua = navigator.userAgent.toLowerCase();
      return /micromessenger/.test(ua) ? true : false;
    },
  },
};
</script>

<style lang="less" scoped>
.isNotweixin {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #fff;
  z-index: 999;
  .isNotweixin-box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    img {
      width: 188rpx;
      height: 188rpx;
      margin-top: 200rpx;
      margin-bottom: 100rpx;
    }
    span {
      padding: 30rpx;
      font-weight: 400;
      font-size: 32rpx;
    }
  }
}
</style>
